<template>
  <a-drawer
    :title="model.id > 0?'编辑轮播图':'添加轮播图'"
    :maskClosable="true"
    width="800"
    placement="right"
    :closable="true"
    @close="hide()"
    :visible="isShow"
  >
    <a-form >
      <a-form-item label="图片" v-bind="formItemLayout">
        <image-upload v-model="model.image" hint="建议尺寸686*294px以上，比例21:9"></image-upload>
      </a-form-item>

      <a-form-item label="序号" v-bind="formItemLayout">
        <a-input-number v-model="model.sort"/>
      </a-form-item>

      <a-form-item label="标题" v-bind="formItemLayout">
        <a-input v-model="model.title"/>
      </a-form-item>

      <a-form-item label="跳转链接" v-bind="formItemLayout">
        <a-input v-model="model.link"/>
      </a-form-item>

      <a-form-item label="是否启用" v-bind="formItemLayout">
        <a-switch v-model="model.enable" @change="onChange"/>
      </a-form-item>
    </a-form>

    <div class="drawer-bootom-button">
      <a-button style="margin-right: .8rem" @click="hide">取消</a-button>
      <a-button @click="handleSubmit" type="primary" :loading="loading">提交</a-button>
    </div>
  </a-drawer>
</template>
<script>


  const formItemLayout = {
    labelCol: {span: 3},
    wrapperCol: {span: 18}
  }
  export default {

    data () {
      return {
        isShow: false,
        model: {
          id: 0,
          title: '',
          image: '',
          link: '',
          enable: '',
          sort: ''
        },

        loading: false,
        formItemLayout,
      }
    },
    methods: {
      show (model = {}) {
        this.isShow = true
        this.model = JSON.parse(JSON.stringify(model))
      },
      hide () {
        this.isShow = false
      },
      onChange (checked) {
        this.model = {...this.model, enable: checked}
      },

      handleSubmit () {
        if (!this.model.image) {
          this.$message.error('上传图片不能为空')
          return
        }

        if (this.model.id > 0) {
          this.edit()
        }else{
          this.add()
        }
      },

      add () {
        this.loading = true
        this.$post('banner', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          console.log(err)
          this.loading = false
        })
      },
      edit () {
        this.loading = true
        this.$put('banner', this.model).then(r => {
          this.loading = false
          this.$emit('success')
          this.hide()
        }).catch(err => {
          this.loading = false
          console.log('err', err)
        })
      }

    }

  }
</script>

<style>
  .avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
  }

  .ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
  }

  .ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
  }

  .banner-image {
    width: 200px;
  }

  .ant-input-number {
    width: 100%;
  }
</style>
